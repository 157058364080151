#root {
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/AvenirNext/AvenirNext-Regular.woff2");
}

@font-face {
  font-family: "Avenir-Bold";
  src: url("./assets/fonts/AvenirNext/AvenirNext-Bold.woff2");
}

@font-face {
  font-family: "Avenir-DemiBold";
  src: url("./assets/fonts/AvenirNext/AvenirNext-DemiBold.woff2");
}

@font-face {
  font-family: "Avenir-Medium";
  src: url("./assets/fonts/AvenirNext/AvenirNext-Medium.woff2");
}

#logo {
}

.pklm-loading {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.pklm-loading div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #18bc9c;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading-text {
  color: #107c66;
  font-weight: bold;
  font-size: 2rem;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pklm-loading div:nth-child(1) {
  left: 8px;
  animation: pklm-loading1 0.6s infinite;
}
.pklm-loading div:nth-child(2) {
  left: 8px;
  animation: pklm-loading2 0.6s infinite;
}
.pklm-loading div:nth-child(3) {
  left: 32px;
  animation: pklm-loading2 0.6s infinite;
}
.pklm-loading div:nth-child(4) {
  left: 56px;
  animation: pklm-loading3 0.6s infinite;
}
@keyframes pklm-loading1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pklm-loading3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes pklm-loading2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
